export function generatePaletteNN(color: {
  r: number;
  g: number;
  b: number;
}): Record<string, number> {
  const input = new Float32Array([color.r, color.g, color.b]);
  return {
    '50-r':
      1 /
      (1 +
        1 /
          Math.exp(
            1.8661608695983887 +
              (1.969373345375061 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.4601062536239624 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.7962396740913391 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '50-g':
      1 /
      (1 +
        1 /
          Math.exp(
            1.9256014823913574 +
              (1.231925129890442 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.3603883981704712 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.8378368616104126 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '50-b':
      1 /
      (1 +
        1 /
          Math.exp(
            1.5751663446426392 +
              (0.6084778904914856 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.0512851476669312 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (1.9236465692520142 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '100-r':
      1 /
      (1 +
        1 /
          Math.exp(
            1.6104178428649902 +
              (2.411083698272705 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.4946855306625366 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.5684743523597717 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '100-g':
      1 /
      (1 +
        1 /
          Math.exp(
            1.6873022317886353 +
              (0.7176924347877502 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.6131407022476196 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.15697577595710754 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '100-b':
      1 /
      (1 +
        1 /
          Math.exp(
            1.0808522701263428 -
              (0.09388591349124908 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.5177313685417175 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (2.6253278255462646 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '200-r':
      1 /
      (1 +
        1 /
          Math.exp(
            1.3480290174484253 +
              (2.2088170051574707 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (1.1226733922958374 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.41870957612991333 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '200-g':
      1 /
      (1 +
        1 /
          Math.exp(
            1.3919278383255005 +
              (0.2666841745376587 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.5987523794174194 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.23637670278549194 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '200-b':
      1 /
      (1 +
        1 /
          Math.exp(
            0.6176453232765198 -
              (0.6029531359672546 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.21029342710971832 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (2.906541109085083 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '300-r':
      1 /
      (1 +
        1 /
          Math.exp(
            0.9194338321685791 +
              (2.3936944007873535 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (1.68363356590271 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.36374539136886597 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '300-g':
      1 /
      (1 +
        1 /
          Math.exp(
            1.1811708211898804 -
              (0.08378893882036209 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.4275716543197632 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.6511568427085876 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '300-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.04160493612289429 -
              (1.0422184467315674 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.1739543229341507 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (3.306165933609009 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '400-r':
      1 /
      (1 +
        1 /
          Math.exp(
            0.5908379554748535 +
              (2.6282155513763428 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (2.483553886413574 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (0.16029347479343414 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '400-g':
      1 /
      (1 +
        1 /
          Math.exp(
            0.9054368138313293 -
              (0.38720616698265076 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.231947422027588 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.0837976932525635 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '400-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.6404189467430115 -
              (1.6898466348648071 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.029696132987737656 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (3.7177767753601074 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '500-r':
      1 /
      (1 +
        1 /
          Math.exp(
            0.3420727550983429 +
              (2.987776756286621 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (3.3403704166412354 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.07194828987121582 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '500-g':
      1 /
      (1 +
        1 /
          Math.exp(
            0.5289734601974487 -
              (0.5567987561225891 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.3352314233779907 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.4835448265075684 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '500-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.2478309869766235 -
              (2.2976744174957275 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.30013492703437805 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (4.339084625244141 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '600-r':
      1 /
      (1 +
        1 /
          Math.exp(
            0.18572008609771729 +
              (2.5128233432769775 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (3.407212495803833 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.20814435184001923 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '600-g':
      1 /
      (1 +
        1 /
          Math.exp(
            0.15741883218288422 -
              (0.7273809909820557 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.2500137090682983 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.6501106023788452 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '600-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.3073996305465698 -
              (2.5112154483795166 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.7157139182090759 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (4.086366176605225 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '700-r':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.25121745467185974 +
              (1.7795124053955078 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (2.7557551860809326 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.0411522202193737 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '700-g':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.43503373861312866 -
              (0.6766132712364197 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (1.150789737701416 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.3406033515930176 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '700-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.1124053001403809 -
              (2.359389305114746 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.943118691444397 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (3.267453908920288 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '800-r':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.42729225754737854 +
              (1.2738885879516602 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (2.573699474334717 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.11818580329418182 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '800-g':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.6730203628540039 -
              (0.770199716091156 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.9060920476913452 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.2600882053375244 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '800-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.32567298412323 -
              (1.9301948547363281 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.8552113175392151 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (2.6513888835906982 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '900-r':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.6309270858764648 +
              (1.0235852003097534 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (2.4659388065338135 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.15058130025863647 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '900-g':
      1 /
      (1 +
        1 /
          Math.exp(
            -0.9010928273200989 -
              (0.7559532523155212 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.7665435075759888 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.1884112358093262 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '900-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.5088001489639282 -
              (1.5986618995666504 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.7353832125663757 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (2.180158853530884 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '950-r':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.166185975074768 +
              (0.5363732576370239 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (2.587523937225342 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (0.061746854335069656 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '950-g':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.2907893657684326 -
              (1.3924223184585571 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) +
              (0.5465696454048157 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) -
              (1.2647709846496582 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
    '950-b':
      1 /
      (1 +
        1 /
          Math.exp(
            -1.8633685111999512 -
              (1.8034374713897705 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.5648569464683533 +
                        8.592248916625977 * (input[0] || 0) -
                        2.0615885257720947 * (input[1] || 0) -
                        7.465658187866211 * (input[2] || 0),
                    )) -
              (0.8856748938560486 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.6313956379890442 -
                        7.673094272613525 * (input[0] || 0) +
                        8.008654594421387 * (input[1] || 0) -
                        1.6121777296066284 * (input[2] || 0),
                    )) +
              (1.8510282039642334 * 1) /
                (1 +
                  1 /
                    Math.exp(
                      0.08400232344865799 -
                        0.9345706105232239 * (input[0] || 0) -
                        7.6551032066345215 * (input[1] || 0) +
                        8.717894554138184 * (input[2] || 0),
                    )),
          )),
  };
}
